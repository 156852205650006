/* YourComponent.css */
.info-hover {
    position: relative;
    cursor: pointer;
  }
  
  .info-hover::after, .info-hover::before {
    visibility: hidden; /* 기본 상태에서는 숨김 */
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
  }
  
  .info-hover::after {
    content: "온도가 높을 수록 예약률이 높습니다"; /* 설명 텍스트 */
    position: absolute;
    white-space: nowrap;
    top: 50%; /* 상위 요소와의 상대적 위치 */
    left: 105%; /* 오른쪽에 위치 */
    transform: translateY(-50%); /* 세로 중앙 정렬 */
    background-color: #3182ce; /* 배경색 */
    color: white; /* 글자색 */
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 14px;
    z-index: 1;
  }
  
  .info-hover::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%; /* 화살표 위치 */
    transform: translateY(-50%);
    border-style: solid;
    border-width: 5px; /* 화살표 크기 */
    border-color: transparent transparent transparent #3182ce; /* 화살표 색상 */
    z-index: 1;
  }
  
  .info-hover:hover::after, .info-hover:hover::before {
    visibility: visible; /* hover 상태에서 보임 */
    opacity: 1;
  }
  